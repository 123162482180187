<template>
  <div
    id="app"
    class="d-flex flex-column flex-grow-1 h-100 justify-content-between"
  >
    <div class="container">
      <div class="row">
        <div class="col">
          <b-img :src="require('./assets/logo.png')" fluid alt="Minerkules"></b-img>
        </div>
      </div>
      <div class="row mt-2 text-start">
        <div class="col-12">
          <div class="card-group">
            <b-card no-title style="" class="m-2 shadow col col-lg-6">
              <h5>
                Wir bieten maßgeschneiderte Konzepte und Umsetzungen in den
                folgenden Bereichen:
              </h5>
              <b-card-text class="">
                <ul>
                  <li>Unternehmensberatung</li>
                  <li>Nationale und EU Förderberatung</li>
                  <li>Forschungsförderung</li>
                  <li>Abwicklungen von Förderungen aller Art</li>
                  <li>Prozessoptimierung</li>
                  <li>Internationaler Handel und Ausschreibungen</li>
                  <li>Vorträge und Trainings für Professionisten</li>
                  <li>Think-Tank für Lösungen zu Problemen aller Art</li>
                  <li>Beratungen im ÖPNV und der Elektromobilität</li>
                </ul>
                <div>Wir bringen langjährige Erfahrung gepaart mit hoher Problemlösungskompetenz mit.</div>
              </b-card-text>
            </b-card>
            <b-card no-title style="" class="m-2 shadow col col-lg-6">
              <h5>
                We offer you tailor-made concepts and implementation in the
                following topics:
              </h5>
              <b-card-text class="">
                <ul>
                  <li>Business consulting</li>
                  <li>Consulting on national and EU funding</li>
                  <li>Research funding</li>
                  <li>Funding administration and support</li>
                  <li>Process optimization</li>
                  <li>International Trade and Tendering</li>
                  <li>Talks and Trainings for professionals</li>
                  <li>Think Tank for solutions for problems of all kinds</li>
                  <li>Consulting on public transport and e-mobility</li>
                </ul>
                <div>We have many years of experience paired with highest competence in problem-solving.</div>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </div>
      <div class="row my-4">
        <div class="col">
          <b-button @click="show = true; impressum = false;" size="lg" variant="outline-dark" pill
            >Kontakt</b-button
          >
          <b-modal v-model="show" hide-header-close :title="title">
            <b-container fluid>
              <b-row class="mb-1 text-center">
                <div class="col-12 col-lg-4 mb-2">
                  <b-img :src="require('./assets/ceo.jpg')" fluid alt="CEO" style="max-width: 130px"></b-img>
                </div>
                <div class="col-12 col-lg-8">
                  MINERKULES GmbH<br />
                  Matthäus-Krenauer-Straße 4<br />
                  8700 Leoben<br />
                  <hr />
                  Telefon: +43 (0) 664 250 250 4<br />
                  Email: carla.chibidziura@gmx.at<br />
                  <template v-if="impressum">
                  <hr />
                  Geschäftsführerin: Mag. Carla Chibidziura<br />
                  FB-Nummer: FN 571836k<br />
                  </template>
                </div>
              </b-row>
            </b-container>

            <template #modal-footer>
              <div>
                <b-button
                  variant="outline-dark"
                  size="sm"
                  class="float-right"
                  @click="show = false;"
                >
                  Schließen
                </b-button>
              </div>
            </template>
          </b-modal>
        </div>
        <div></div>
      </div>
    </div>
    <footer class="container mt-2">
      <div class="row m-2">
        <div class="col-6 text-nowrap">© 2022 Minerkules GmbH</div>
        <div class="col-6"><b @click="show = true; impressum = true;" style="cursor: pointer;">Impressum</b></div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  
  computed: {
    title () {
      return this.impressum ? 'Impressum' : 'Kontakt';
    }
  },

  data() {
    return {
      show: false,
      impressum: false,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 60px;
  flex-flow: column;
}

body {
  background: #e4e4e4 !important;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  min-height: 100vh;
}

.minerkules-bg {
  background: #e4e4e4 !important;
}

@media (max-width: 992px) {
  .card-group {
    all: revert !important;
  }
}

</style>
